<template>
    <v-dialog v-model="dialog" :max-width="dialogMaxWidth || '800px'">
        <template v-slot:activator="{ on }">
            <btn
                :preset="triggerButtonPreset || 'add'"
                v-on="on"
                :size="triggerButtonSize || 'small'"
                :label="triggerButtonLabel"
            />
        </template>
        <v-card>
            <Page :title="dialogTitle">
                <div slot="header" class="d-flex">
                    <slot name="buttons" />
                    <btn
                        v-if="formSchema"
                        :preset="dialogActionButtonPreset || 'save'"
                        @click="clickAction"
                        :label="dialogActionButtonLabel"
                    />
                    <v-spacer></v-spacer>
                    <btn preset="cancel" @click="clickClose" small />
                </div>
                <smart-form
                    v-if="formModel && formSchema"
                    :schema="formSchema"
                    v-model="formModel"
                    @input="updatedForm"
                    v-bind="$attrs"
                />
                <slot />
            </Page>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PopupForm',
    props: {
        triggerButtonLabel: String,
        triggerButtonColor: String,
        triggerButtonSize: String,
        triggerButtonPreset: String,

        dialogMaxWidth: String,
        dialogTitle: String,
        dialogActionFunc: Function,
        dialogActionButtonLabel: String,
        dialogActionButtonPreset: String,

        value: Object,
        formSchema: [Object, Array],
    },
    data() {
        return {
            dialog: false,
            formModel: null,
        };
    },
    methods: {
        clickClose() {
            this.dialog = false;
        },
        clickAction() {
            this.$emit('input', this.formModel);
            if (this.dialogActionFunc() == true) {
                this.clickClose();
            }
        },
        updatedForm() {
            console.log('update form 2', this.formModel);
            this.$emit('input', { ...this.formModel });
            console.log('update form 3', this.formModel);
        },
    },
    mounted() {
        this.formModel = this.value || {};
    },
};
</script>
