<template>
    <popup
        v-if="useFormSchema"
        :dialog-title="addDialogTitle || pageTitle"
        dialog-action-button-label="Save"
        :dialog-action-func="saveNewRecord"
        :form-schema="useFormSchema"
        v-model="useFormModel"
        :dataSets="dataSets"
    />
</template>

<script>
import Popup from '@/components/form/Popup';

import api from '@/api';

export default {
    name: 'AddPage',
    components: { Popup },
    props: {
        pageTitle: String, // eg 'Account'

        collectionName: String, // eg 'account'
        module: { type: String, default: 'admin' }, /* eg admin bill accounts property */

        addDialogTitle: String,

        formSchema: [Object, Array],
        formModel: Object,

        dataSets: Object,

        baseUrl: String,
    },
    data() {
        return {
            useFormSchema: null,
            useFormModel: null,
        };
    },
    methods: {
        getUrl() {
            if (this.baseUrl) {
                return this.baseUrl;
            }

            return `${this.collectionName.toLowerCase()}`;
        },
        getEntityCode() {
            return this.collectionName.toLowerCase();
        },
        async saveNewRecord() {
            console.log('action button for the form has been pressed.', this.useFormModel);
            const result = await api.post(this.$store, this.getUrl(), api.formDataToObject(this.useFormModel));

            if (result && result.data && result.data.success) {
                const newRecord = result.data[this.collectionName];

                if (newRecord && newRecord.id) {
                    await this.$router.push(`/auth/${this.module}/${this.getUrl()}/${newRecord.id}`);
                }
            }
        },
        async getSchema() {
            if (this.formSchema) {
                this.useFormSchema = this.formSchema;
            } else {
                const schemaResult = await api.get(this.$store, `form/schema/${this.getEntityCode()}/list`);
                if (!schemaResult || !schemaResult.data) return;
                const { formSchema } = schemaResult.data;
                this.useFormSchema = formSchema;
            }
        },
        async init() {
            this.useFormModel = this.formModel || {};
            await this.getSchema();
        },
    },
    async created() {
        await this.init();
    },
};
</script>

<style scoped></style>
