<template>
    <Page :title="pageTitle" :icon="pageIcon">
        <div class="constrain-width">
            <slot name="above-table" />

            <smart-table
                v-if="tableData && tableData.length && tableHeaders"
                :headers="tableHeaders"
                :items="tableData"
                :items-per-page="itemsPerPage || 20"
                class="elevation-1"
                @click:row="clickRow"
                :filter-text.sync="filterText"
                dense
                :isFilter="isFilter"
                isHistory
                :key="`smarttable${tableData.length}${useReloadTrigger}`"
            />
            <slot name="below-table" />
        </div>
        <template v-slot:header>
            <slot name="page-header-before" />
            <toolbar>
                <add-page
                    v-if="addDialogTitle"
                    :page-title="pageTitle"
                    :collection-name="collectionName"
                    :module="module"
                    :add-dialog-title="addDialogTitle"
                    :form-schema="formSchema"
                    :data-sets="dataSets"
                    :base-url="baseUrl"
                />
                <slot name="toolbar" />
            </toolbar>
            <slot name="page-header-after" />
        </template>
    </Page>
</template>

<script>
import AddPage from '@/components/pages/Add';

import api from '@/api';

export default {
    name: 'ListPage',
    components: { AddPage },
    props: {
        pageTitle: String, // eg 'Account'
        pageIcon: String, // eg 'mdi-folder-outline'

        collectionName: String, // eg 'account'
        module: { type: String, default: 'admin' } /* eg admin bill accounts property */,

        itemsPerPage: Number,
        isFilter: Boolean,

        addDialogTitle: String,
        dataSets: Object,

        baseUrl: String,

        reloadTrigger: Number,
    },
    data() {
        return {
            formSchema: null,
            filterText: '',
            formModel: {},
            tableData: null,
            isHistory: null,
            tableHeaders: null,
            useReloadTrigger: null,
        };
    },
    methods: {
        clickRow(row) {
            const url = `/auth/${this.module}/${this.getUrl()}/${row.id}`;
            this.$router.push(url);
        },
        getUrl() {
            if (this.baseUrl) {
                return this.baseUrl;
            }

            return `${this.collectionName.toLowerCase()}`;
        },
        getEntityCode() {
            return this.collectionName.toLowerCase();
        },
        async saveFilterText(filterText) {
            if (this.formCode) {
                await this.$store.dispatch('setFormData', {
                    formCode: this.formCode,
                    formValue: filterText,
                });
            }
        },
        async getTableData() {
            const schemaResult = await api.get(this.$store, `form/schema/${this.getEntityCode()}/list`);
            // console.log('getFormData - schema ', this.getEntityCode(), schemaResult);
            if (!schemaResult || !schemaResult.data) return;

            const { formSchema, tableHeaders } = schemaResult.data;

            this.formSchema = formSchema;
            this.tableHeaders = tableHeaders;

            const result = await api.get(this.$store, this.getUrl());
            console.log('list getTableData result', result.data);
            this.tableData = [...result.data];
            this.$emit('data', this.tableData);
        },
        async init() {
            await this.getTableData();

            this.formCode = `ListPage${this.getEntityCode()}`;
            if (this.isHistory) {
                this.filterText = await this.$store.dispatch('getFormData', this.formCode);
            } else {
                await this.saveFilterText('');
            }
        },
    },
    watch: {
        async filterText() {
            await this.saveFilterText(this.filterText);
        },
        async reloadTrigger() {
            console.log('List Page - reloadTrigger', this.reloadTrigger);
            await this.getTableData();
            this.useReloadTrigger = this.reloadTrigger;
        },
    },
    async created() {
        this.isHistory = !!this.$route.query.isHistory;
        await this.init();
    },
};
</script>

<style scoped></style>
