<template>
    <list-page
        page-title="Bill Profile"
        page-icon="mdi-arrow-decision-outline"
        collection-name="billProfile"
        module="bill"
        :items-per-page="20"
        add-dialog-title="Add Bill Profile"
        is-filter
    />
</template>

<script>
import ListPage from '@/components/pages/List';


export default {
    name: 'AccountList',
    components: { ListPage },

};
</script>

<style scoped></style>
